import React, { useState, useEffect, lazy, Suspense } from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet' 



const LinkBox = lazy(() => 
import (`./link_box`))

const LinkBox2 = lazy(() => 
import (`./link_box2`))

const LinkBox3 = lazy(() => 
import (`./link_box3`))



export default function LandingPage(props)  {
    const [menu, setMenu] = useState(false)

    const [timer, setTimer] = useState(false)


    useEffect(() => {
       setTimeout(() => {
         setTimer(true)
       }, 1500);
    })



        return (
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Permanent Makeup Studio & Training Academy | Bethlehem, Easton, Allentown, Philly, New York | Pennsylvania, New Jersey, New York, Delaware, Maryland, and beyond</title> 
                <link rel="canonical" href="https://www.cosmetink.com/" />
                <meta name="description" content="Beginner Certification Classes - Learn Microblading, Ombre Brows, Lip Blush - NO EXPERIENCE NEEDED - NO ESTHETICIAN LICENSE REQUIRED" />
                <meta name="keywords" content="ombre brows training, permanent makeup training, permanent makeup school, permanent makeup classes beginners, microblading certification, ombre brows certification, lip blush training, lip blush certification, best microblading training, aamp training, beauty school pa, learn microblading, tattoo makeup, cosmetic ink, permanent makeup, microblading, best microblading, semi-permanent makeup, microbladed eyebrows, microblading de cejas" />

            </Helmet>    

  
            <div className='main-body-container'>
                <div className='main-title c blk'>
                <div className='title2 wht c studio'>
                        COSMET<span className='moving-grad2'>INK</span> <span className='reg-nav'>
                        ®</span>
                        <div className='title3'>
                        Permanent Cosmetic Professionals
                        </div>
            
                    </div>
                   <div className='gradient-underline'></div>
                 
                 <a target="_blank" href="https://studio.cosmetink.com" className='top-links' alt="Permanent Makeup Studio in Lehigh Valley, Pennsylvania. Nanoblading, Nano Brows, Microblading, Ombré Brows, and Lip Blushing">
                    Book Services
                 </a>
                 <a target="_blank" href="https://academy.cosmetink.com" className='top-links' alt="Permanent Makeup Training Classes Academy in  Lehigh Valley, Pennsylvania. Learn Permanent Makeup. Get Certified Today.">
                    Training Classes
                 </a>
                 <a target="_blank"  href="https://shop.cosmetink.com" className='top-links' alt="Permanent Makeup Shop. Top Permanent Makeup Brands.  PMU Machines, Needles, and Pigments.">
                    Shop Products
                 </a>
              
                </div>

                
         

             <div className='main-body-container2'>           
             <Suspense fallback={<div>
                      </div>}>
      
               <LinkBox />


               <LinkBox2 />

               <LinkBox3 />
               </Suspense>
               </div>    

               <br/>
               <div className='footer wht'>

               Copyright © 2024, COSMETINK
               <br/>
                  
         
                  3859 Nazareth Pike
               Bethlehem, PA 18020
        
           
     
           

      
               </div>  
            </div>
            </>
            ) 
       
        
    }
